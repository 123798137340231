import React, { Component } from 'react';
import { imagesLocation } from '../../data';
import { NumberFormat } from '../../helpers/numberFormat'

class FlightDetailsFlight extends Component {

  render() {
    const { flight, details } = this.props

    return (
      <div className="card">
        <div className="card-body">
          <div className="airlines-logo">
            <img src={details.airlines_image} alt={details.airlines} />
            <div>
              {details.airlines} <br />
              <a href={details.url_info} title="flightradar24" target="_blank" rel="noopener noreferrer">{details.flight_number}</a>
            </div>
          </div>
          <h5 className="card-title">Departing flight: <span className="text-nowrap">{details.date}</span></h5>
          <div className="flight-time">
            <p className="card-text">{details.start_time_airport}</p>
            <p className="card-text flight-time-duration">{details.duration}</p>
            <p className="card-text">{details.stop_time_airport}</p>
          </div>
          <p className="card-text flight-price">
            <a href={details.url} target="_blank" rel="noopener noreferrer" className="btn btn-success float-right btn-book">Book flight</a>
            <NumberFormat value={details.price} digits={2} currency={details.price_curr_code} />

            {(details.error) ? (
              <span className="price-error">
                <img src={`${imagesLocation}error.png`} className="icon-error" alt="" />
                {details.error_message}
              </span>
            ) : (flight.updating) ? (
              <img src={`${imagesLocation}loader-small.gif`} className="loader-small" alt="" />
            ) : (flight.updated) && (
              <img src={`${imagesLocation}success.png`} className="icon-success" alt="" />
            )}
          </p>
        </div>
      </div>
    )
  }
}

export default FlightDetailsFlight;