import React, { Component } from 'react';
import ResultsSelect from '../../components/Results/select'
import { maxPriceDefault, weekendDefault, fromDefault } from '../../data';

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: fromDefault,
      weekend: weekendDefault,
      maxPrice: maxPriceDefault
    }
  }

  handleSearchFlights = (e) => {
    e.preventDefault();
    let params = {
      week: this.state.weekend.value,
      dep: this.state.from.ports,
      text: this.state.from.value,
      key: this.state.from.name,
      max_price: this.state.maxPrice
    };
    this.props.handleSearchFlights(params);
  }

  handleFromChange = (selectedOption) => {
    this.setState({ from: selectedOption });
  }

  handleWeekendChange = (selectedOption) => {
    this.setState({ weekend: selectedOption });
  }

  handleMaxPriceChange = (e) => {
    this.setState({ maxPrice: Number(e.target.value) });
  }

  render() {
    const { maxPrice } = this.state

    return (
      <div className="searchForm">
        <form className="justify-content-center" onSubmit={this.handleSearchFlights}>
          <div className="form-group">
            <label>From</label>
            <ResultsSelect name="airports" onChange={this.handleFromChange} />
          </div>
          <div className="form-group">
            <label>When</label>
            <ResultsSelect name="weekend" onChange={this.handleWeekendChange} />
          </div>
          <div className="form-group mr-2 group-price">
            <label>Max price</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">£</span>
              </div>
              <input type="number" className="form-control" value={maxPrice} min="10" max="10000" onChange={this.handleMaxPriceChange} />
            </div>
          </div>
          <button type="submit" className="btn btn-success">Find	&raquo;</button>
        </form>
      </div>
    );
  }
}

export default SearchForm;
