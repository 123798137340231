import _ from 'lodash';
import * as flightsApi from '../helpers/flightsApi'
import { action, observable, computed } from "mobx";
import { flightsPerPage, fetchConfig } from '../data';

class GroupStore {
  id = null;
  @observable name = null;
  @observable flights = [];
  @observable isLoaded = false;
  @observable maxPrice = null;
  @observable activePage = null;
  @observable open = false;
  @observable abortController = new window.AbortController();

  @computed get flightsPage() {
    return this.flights.slice(flightsPerPage * (this.activePage - 1), flightsPerPage * this.activePage);
  }

  constructor(id) {
    this.id = id;
  }

  findById = (flightId) => {
    return _.find(this.flights, (el) => el && (el.id === flightId));
  }

  @action reset(searchParams) {
    this.open = false;
    this.name = '';
    this.flights = [];
    this.isLoaded = false;
    this.maxPrice = searchParams.maxPrice;
  }

  @action initGroup(group) {
    this.isLoaded = true;
    this.activePage = group.activePage;
    this.flights = group.flights;
    this.name = group.name;
    this.open = (group.id === 0);
  }

  @action toggleOpen() {
    this.open = !this.open;
  }

  @action pageChange = (page) => {
    if (page <= Math.ceil(this.flights.length / flightsPerPage)) {
      this.activePage = page;
    }
  }

  @action priceUpdating = (flightId) => {
    let flights = this.findById(flightId);
    if (flights) {
      flights.updating = true;
    }
  }

  @action updatingAborted = (flightId) => {
    let flights = this.findById(flightId);
    if (flights) {
      flights.updating = false;
    }
  }

  @action priceError = (flightId, error_message, flight_id) => {
    let flights = this.findById(flightId);
    if (flights) {
      flights.updating = false;
      Object.assign((flights.from.id === flight_id) ? flights.from : flights.to, {
        error: true, error_message: 'Ticket not available'
      }); // for now don't show passed error_message
    }
  }

  @action priceLimitError = (flightId, priceFrom, priceTo) => {
    let flights = this.findById(flightId),
      changed = false,
      errorProps = { error: true, error_message: 'Ticket too expensive' };
    if (flights) {
      flights.updating = false;
      if (priceFrom !== (flights.from.price)) {
        Object.assign(flights.from, errorProps, { price: priceFrom });
        changed = true;
      }
      if (priceTo !== flights.to.price) {
        Object.assign(flights.to, errorProps, { price: priceTo });
        changed = true;
      }
      if (changed) {
        flights.price = priceFrom + priceTo;
      }
    }
  }

  @action priceUpdated = (flightId, priceFrom, priceTo) => {
    let flights = this.findById(flightId),
      changed = false;
    if (flights) {
      Object.assign(flights, { updating: false, updated: true });
      if (priceFrom !== flights.from.price) {
        flights.from.price = priceFrom;
        changed = true;
      }
      if (priceTo !== flights.to.price) {
        flights.to.price = priceTo;
        changed = true;
      }
      if (changed) {
        flights.price = priceFrom + priceTo;
        this.flights = _.sortBy(this.flights, (el) => { // sorting flights by price (if any price changed)
          return el.price;
        });
      }
    }
  }

  flightUpdated = (flightUpdate, flight) => {
    if (flightUpdate.error) {
      this.priceError(flight.id, flightUpdate.error, flightUpdate.id);
    } else if ((flightUpdate[0].priceLocal + flightUpdate[1].priceLocal) > this.maxPrice) {
      this.priceLimitError(flight.id, flightUpdate[0].priceLocal, flightUpdate[1].priceLocal);
    } else {
      this.priceUpdated(flight.id, flightUpdate[0].priceLocal, flightUpdate[1].priceLocal);
    }
  }

  @action flightRemove = (flightId) => {
    let flight = this.findById(flightId),
      lastPage;

    if (flight) {
      this.flights = this.flights.filter((el) => {
        return (el && (el.id !== flightId));
      }); // remove flight - returns group without current flight
      lastPage = Math.ceil(this.flights.length / flightsPerPage);
      if (this.activePage > lastPage) { // happens when the only flight from last page is removed
        this.activePage = lastPage;
      }
    }
  }

  isFlightValid = (flightUpdate, maxPrice) => {
    return !(flightUpdate.error || ((flightUpdate[0].priceLocal + flightUpdate[1].priceLocal) > maxPrice));
  }

  handleFlightUpdateError = (err, config, params) => {
    if (err.name === 'AbortError') {
      this.updatingAborted(params.flight.id);
    } else {
      console.log('Fetch price reloaded', params);
      this.fetchFlightUpdate(params.flight, config);
    }
  }

  fetchFlightUpdate = async (flight, config) => {
    this.priceUpdating(flight.id);
    let flightUpdate = await flightsApi.get(flight, this.id, config, this.handleFlightUpdateError);
    this.flightUpdated(flightUpdate, flight);
    if (!this.isFlightValid(flightUpdate, this.maxPrice)) {
      setTimeout(() => { // animation of hiding, 1 sec later real remove
        this.flightRemove(flight.id);
      }, 1000);
    }
  }

  @action resetAbortController = () => {
    this.abortController.abort(); // when clicked on search again, previous query will be aborted
    this.abortController = new window.AbortController();
  }

  handleSearchFlights = (searchParams) => {
    this.reset(searchParams);
    this.resetAbortController();
    let config = Object.assign({}, { signal: this.abortController.signal }, fetchConfig);
    return this.fetchFlights(_.clone(searchParams), config);
  }

  fetchFlights = async (params, config) => {
    let group = await flightsApi.getGroup(params, config, this.handleFetchFlightsError, this.id);
    this.initGroup(group);
  }

  handleFetchFlightsError = (err, config, params) => {
    if (err.name === 'AbortError') {
      console.log('fetchFlights aborted', params);
    } else {
      console.log('fetchFlights error - reloaded', params);
      this.fetchFlights(params, config);
    }
  }
}

export default GroupStore;
