import React, { Component } from 'react';
import DevTools from 'mobx-react-devtools'
import './App.css';
import Homepage from './containers/Homepage'
import Header from './components/Header'
import Footer from './components/Footer'

class App extends Component {
  render() {
    return (
      <div className="container-main">
        <Header />

        {process.env.NODE_ENV === 'development' ? <DevTools /> : null}

        <Homepage />

        <Footer />
      </div>
    );
  }
}

export default App;
