import React, { Component } from 'react';
import Img from 'react-image';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import FlightDetailsFlight from './flight';
import { NumberFormat } from '../../helpers/numberFormat'

class FlightDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flight: null
    };
  }

  handleShowFlightDetails = (flight) => {
    this.setState({ flight })
  }

  handleCloseFlightDetails = () => {
    this.setState({ flight: null });
  }

  render() {
    const { flight } = this.state

    return (
      <div>
        {
          flight &&
          <Modal isOpen={true} toggle={this.handleCloseFlightDetails} className="modal-lg flight-details">
            <ModalHeader toggle={this.handleCloseFlightDetails}>
              <Img src={flight.image_wide} alt={flight.city} />
              <Img className="flight-flag" src={flight.flag} alt={flight.country} />
            </ModalHeader>
            <ModalBody>
              <h3>
                <span className="float-right ml-2"><NumberFormat value={flight.price} digits={2} currency={flight.price_curr_code} /></span>
                {flight.from.city}
                <span className="arrow-both"></span>
                {flight.city}
              </h3>
              <FlightDetailsFlight flight={flight} details={flight.from} />
              <FlightDetailsFlight flight={flight} details={flight.to} />
            </ModalBody>
          </Modal>
        }
      </div>
    )
  }
}

export default FlightDetails;