import { observable } from "mobx";
import GroupStore from './group';
import { weekendParts } from '../data';

class GroupsStore {
  @observable groups = [];

  constructor() {
    [...Array(weekendParts)].forEach((x, i) => {
      this.groups[i] = new GroupStore(i);
    })
  }
}

export default GroupsStore;
