import React, { Component } from 'react';
import SearchForm from '../../components/SearchForm'
import Loader from '../../components/Loader'
import FlightDetails from '../../components/FlightDetails'
import ResultsGroup from '../../components/Results/group';
import { weekendParts } from '../../data';
import { observer, inject } from "mobx-react"

@inject('groupsStore')
@observer
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.groupRefs = [];
    this.state = {
      weekendParts: weekendParts,
      flight: null,
      loadingFlights: false
    }
  }

  handleShowFlightDetails = (flight) => {
    this.flightDetailsRef.handleShowFlightDetails(flight);
  }

  handleSearchFlights = (params) => {
    this.setState({ loadingFlights: true });
    for (var i = 0; i < this.state.weekendParts; i++) {
      this.handleSearchFlight(i, params);
    }
  }

  handleSearchFlight = async (i, params) => {
    await this.props.groupsStore.groups[i].handleSearchFlights(params);
    this.handleGroupFetched();
  }

  handleGroupFetched = () => {
    this.setState({ loadingFlights: false })
  }

  setGroupRef = (resultsGroup) => {
    this.groupRefs[resultsGroup.props.id] = resultsGroup;
  }

  setFlightDetailsRef = (flightDetails) => {
    this.flightDetailsRef = flightDetails;
  }

  render() {
    const { loadingFlights, weekendParts, flight } = this.state;

    return (
      <div className="container">
        <SearchForm
          handleSearchFlights={this.handleSearchFlights}
          handleMaxPriceChange={this.handleMaxPriceChange}
        />
        {loadingFlights && <Loader />}

        <div className="flight-results">
          {[...Array(weekendParts)].map((x, i) =>
            <ResultsGroup
              id={i}
              key={i}
              ref={this.setGroupRef}
              loadingFlights={loadingFlights}
              handleShowFlightDetails={this.handleShowFlightDetails}
              handleGroupOpened={this.handleGroupOpened}
              handleGroupFetched={this.handleGroupFetched}
            />
          )}
        </div>

        <FlightDetails flight={flight} handleCloseFlightDetails={this.handleCloseFlightDetails} ref={this.setFlightDetailsRef} />
      </div>
    );
  }
}

export default Homepage;
