import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import ResultsFlight from './flight'
import { flightsPerPage } from '../../data';
import { observer, inject } from "mobx-react"

@inject('groupsStore')
@observer
class ResultsGroup extends Component {
  constructor(props) {
    super(props);
    this.group = this.props.groupsStore.groups[this.props.id];
  }

  render() {
    const { handleShowFlightDetails } = this.props;
    const { open, isLoaded, name, activePage, error, flights, flightsPage, fetchFlightUpdate } = this.group;

    return (
      <div>
        {isLoaded &&
          <div className={open ? 'group' : 'group closed'}>
            <h2 className="group-name" onClick={() => this.group.toggleOpen()}>{name} <span>({flights.length} flights)</span></h2>
            {open && (
              <div className="group-flights row">
                {flightsPage.map(flight =>
                  <ResultsFlight
                    flight={flight}
                    group={this.state}
                    key={flight.id}
                    handleShowFlightDetails={handleShowFlightDetails}
                    fetchFlightUpdate={fetchFlightUpdate}
                  />
                )}

                {(flights.length > flightsPerPage) && (
                  <nav className="flights-nav">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={flightsPerPage}
                      totalItemsCount={flights.length}
                      pageRangeDisplayed={5}
                      onChange={(pageNumber) => this.group.pageChange(pageNumber)}
                      hideDisabled={true}
                      nextPageText="&raquo;"
                      prevPageText="&laquo;"
                      hideFirstLastPages={true}
                      itemClass="page-item"
                      linkClass="page-link"
                      innerClass="pagination justify-content-center"
                    />
                  </nav>
                )}

                {(error && error === 'refresh') ? (
                  <div className="no-results">
                    Unfortunately our search engine is not able to fetch flights at this moment. <br />
                    Please try again later.
              </div>
                ) : (!flights.length) && (
                  <div className="no-results">No flights available</div>
                )}
              </div>
            )}
          </div>
        }
      </div>
    )
  }
}

export default ResultsGroup
